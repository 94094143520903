import React from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Button, Menu, MenuItem } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.css';
import logo from "../Home/img/appLogo.svg";
import { Navbar, Nav } from 'react-bootstrap';
import "../../App.scss"

const NavBar = () => {
    const navigate = useNavigate();
    const isLoggedIn = localStorage.getItem('token');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [expanded, setExpanded] = React.useState(false);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleLogout = () => {
        setAnchorEl(null);
        setExpanded(false);
        localStorage.clear()
        navigate('/login')
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <Navbar expand="lg" expanded={expanded} className="col-12 px-sm-5" onToggle={(val) => setExpanded(val)}>
            {/* <div className="d-flex h-100 w-100 flex-wrap justify-content-between align-items-center"> */}
            <div className="container">
                <Navbar.Brand as={Link} to="/" onClick={() => setExpanded(false)}>
                    <div className="d-flex align-items-center">
                        <img src={logo} alt="logo picture here" />
                        <h5 className='m-0' href="/about">Dim<span className='link'>Ekspert</span></h5>
                    </div>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto column-gap-2">
                        <NavLink to="/about" className="nav-link" onClick={() => setExpanded(false)}>Haqqımızda</NavLink>
                        <NavLink to="/contact" className="nav-link" onClick={() => setExpanded(false)}>Əlaqə</NavLink>
                        {isLoggedIn && <NavLink to="/account" className="nav-link" onClick={() => setExpanded(false)}>Şəxsi kabinet</NavLink>}
                    </Nav>
                </Navbar.Collapse>
                {isLoggedIn ?
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto gap-2">
                            <Button onClick={handleLogout} variant='outlined' size="large" className='gray' type='button'>Çıxış</Button>
                        </Nav>
                    </Navbar.Collapse> :
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto gap-2">
                            <Button variant="contained" size="large" className='white' type='button' onClick={() => { navigate("/register"); setExpanded(false) }}>Qeydiyyat</Button>
                            <Button variant='outlined' size="large" className='gray' type='button' onClick={() => { navigate("/login"); setExpanded(false) }}>Daxil ol</Button>
                        </Nav>
                    </Navbar.Collapse>
                }
            </div>
            {/* </div> */}
        </Navbar>
    );
}

export default NavBar;
