import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import Home from "./components/Home/Home";
import About from "./components/About/About";
import NotFound from "./components/NotFound/NotFound";
import Register from './components/Register/Register'
import Login from './components/Login/Login'
import ConfirmEmail from './components/ConfirmEmail/ConfirmEmail';
import ChangePassword from "./components/ChangePassword/ChangePassword"
import Account from './components/Account/Account';
import Nav from './components/Nav/Nav';
import Loading from './comps/Loading';
import "./App.scss"
import OTP from './components/Register/OTP';
import Contact from './components/Contact/Contact';

const App = () => {

  const loginData = localStorage.getItem("loginData")
  const token = localStorage.getItem("token")

  const [loading, setloading] = useState(false);
  const location = useLocation();

  return (
    <>
    <Nav />
    <div className="home container d-flex flex-column justify-content-center">
      <Routes>
        <Route path="/changePassword" element={<ChangePassword />} />
        <Route path="/confirmEmail" element={<ConfirmEmail />} />
        {token && loginData &&
          <>
            <Route path="/account/*" element={<Account />} />
            {/* <Route path="/" element={<Account />} /> */}
            <Route path="/login" element={<Navigate to="/account" />} />
            <Route path="/register" element={<Navigate to="/account" />} />
          </>
        }

        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/otp" element={<OTP />} />
        <Route path="/404" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </div>
    </>
  );
}

export default App;
