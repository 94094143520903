import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import { request } from '../../../config';
import html2pdf from 'html2pdf.js';
import ReactDOMServer from 'react-dom/server';
import pdf from "../../Home/img/pdf.svg";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const generatePdf = (component, data) => {
    const element = React.createElement(component, { result: data?.appeal });
    const html = ReactDOMServer.renderToStaticMarkup(element);

    const fullHtml = html;

    const opt = {
        pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
        margin: 10,
        image: { type: 'jpeg', quality: 0.98 },
        filename: `DimEkspert - ${JSON.parse(localStorage.getItem('loginData'))?.personalInfo?.name} - ${data?.name}.pdf`,
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };

    html2pdf().from(fullHtml).set(opt).save();
};

const ResultTable = ({ result }) => (
    <TableContainer component={Paper} className="mx-auto" style={{ width: 'inherit', borderRadius: '8px' }}>
        <Table aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell>İxtisas kodu</TableCell>
                    <TableCell>Tədris forması</TableCell>
                    <TableCell>İxtisas adı</TableCell>
                    <TableCell>Universitet</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {result?.map(row => (
                    <TableRow
                        key={row?.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row">
                            {row?.specialityCode}
                        </TableCell>
                        <TableCell>{row?.studyType}</TableCell>
                        <TableCell>{row?.specialityName}</TableCell>
                        <TableCell>{row?.universityName}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
)

export default function Result() {
    const [result, setResult] = useState()

    const { id } = useParams();
    const navigate = useNavigate();

    const handleGeneratePdf = () => {
        generatePdf(ResultTable, result);
    };

    useEffect(() => {
        (async () => {
            try {
                const response = await request(`user/v1/appeals/${id}`, null, 'POST');

                if (response.code === "SUCCESS") {
                    setResult(response?.data);
                }

            } catch (error) {
                console.error(error);
            }
        })();
    }, [id])

    return (
        result && <div className='col-11 mx-auto animate__animated animate__fadeIn'>
            <div className="d-flex align-items-center justify-content-between flex-wrap mb-4 gap-2">
                <h5 className='m-0 ps-3'><Button onClick={() => navigate("/account/appeals")} variant='plain'><ArrowBackIosNewIcon /></Button> {result?.name}</h5>
                <Button onClick={handleGeneratePdf} variant='plain' className='ms-auto'><img src={pdf} alt="user picture here" /> PDF kimi yüklə</Button>
            </div>
            <ResultTable result={result?.appeal} />
        </div>
    );
};