import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import "../../App.scss";
import 'animate.css';
import logo from "../Home/img/appLogo.svg";
import { NavLink } from 'react-router-dom';

export default function Home() {
    return (
        <div className="d-flex align-items-center justify-content-center about mb-4">
            <div className="row justify-content-center col-10 p-5 gap-3 animate__animated animate__fadeIn animate__slow">
                <div className="d-flex align-items-center justify-content-center">
                    <h2 className='m-0'> Haqqımızda</h2>
                </div>
                <div>
                    <h5>Təqdimat</h5>
                    <p>Bu sistem universitetlərə qəbul imtahanlarında iştirak etmiş, hər hansı bal toplamış və ixtisas seçimi mərhələsində data analizi əsasında müəyyən tövsiyələr əldə etmək istəyən abituriyentlərə dəstək məqsədi ilə yaradılmışdır. Sistemdə qeydiyyatdan keçmək, sistemə daxil olmaqla data analizi əsasında formalaşmış ehtimal və tövsiyələri almaq imkanından faydalana bilərsiz.</p>
                </div>
                <div>
                    <h5>Missiyamız</h5>
                    <p>Qəbul imtahanlarında iştirak etmiş abituriyentlərə ali təhsil müəssisəsi və ixtisas seçimi ilə bağlı əsaslandırılmış qərarlar qəbul etməyə kömək etmək, sistemin istifadəçiləri və hazırlayanlar, həmçinin təhsil ekspertləri ilə birlikdə bu məsələdə dəstək olmaqdır.</p>
                </div>
                <div>
                    <h5>Vizyonumuz</h5>
                    <p>Biz hər bir abituriyentə öz düşüncələrinə alternativ olaraq data analizi əsasında qərar qəbul etmək imkanlarının yaradılmasına , ali təhsilə sahib olmaq arzularına doğru irəliləməsinə əhəmiyyətli şəkildə dəstək olmağı nəzərdə tuturuq.</p>
                </div>
                <div>
                    <h5>Dəyərlərimiz</h5>
                    <p>Öz ideyalarımız reallaşdırırıq. <br /> Digərlərinin ideyalarını reallaşdırsaq mütləq qaydada onu qiymətləndirəcəyik. <br /> Təklif, tənqid, irad, xoş sözlər – hər birinə açıq yanaşmamız var.</p>
                </div>
                <div>
                    <h5>Komandamız</h5>
                    <p>Komanda üzvlərimiz proqram təminatı hazırlanması, data analitikası və təhsil sahəsi üzrə ixtisaslaşmış peşəkarlardan ibarətdir.</p>
                </div>
                <div>
                    <h5>Tarixçəmiz</h5>
                    <p>Sistemin Azərbaycanda ideya müəllifi ilk dəfə bizim komanda olmuşdur. Bu sistemin komandamızla birlikdə hazırlanması ideyası yerli şirkətlərdən birinə təklif edilmiş,   lakin yerli şirkət tərəfindən komandamıza ağır şərtlər təklif olunmuş, tərəfimizdən imtina cavabı belə gözlənilmədən 3 gün  sonra həmin yerli şirkət tərəfindən bizim ideya üzrə işlərə başlanmış, şirkət bunu öz ideyası kimi təqdim etməyə başlamışdır. Nəticə olaraq komandamız çox sürətlə işlərə başlamış və çox sürətlə sistemin tətbiq olunması həyata keçirilməkdədir..</p>
                </div>
                <div>
                    <h5>Minnətdarlıq</h5>
                    <p>İstifadəçilərimizə, tərəfdaşlarımıza, bizi dəstəkləyənlərə və sistemin tətbiqində əməyi olan hər kəsə təşəkkür edirik. Daim sizlərlə əlaqədə olacağımıza və dəyərli gənclərimizə dəstək olmağa var gücümüzlə çalışacağımıza əmin ola bilərsiz. Gənclər gələcəyimizdirlər.</p>
                </div>
                <div>
                    <h5>Qeyd</h5>
                    <p>Biz sizin seçim və fikirlərə hörmətlə yanaşırıq, bütün tövsiyələrimiz əvvəlki illərin statistik nəticələri, tendensiyar, cari il üzrə universitetlərdə tədris olunan ixtisasları plan yerlərinin sayı və sair digər məlumatlar əsasında hazırlanmışdır. Nəticələrin burada verilən tövsiyələrdən müəyyən qədər fərqlənməsi hallarının olması təbiidir. Biz dataları analiz edib bu nəticələri maksimum reallığa uyğunlaşdırmağa çalışırıq. </p>
                </div>
                <div>
                    <h5>Yekun</h5>
                    <p>Əminik ki, sizlərlə birlikdə ölkəmizin inkişafına dəyər qata biləcəyik. Güc birlikdədir. HƏR ZAMAN XOŞ GƏLMİŞSİNİZ. Zamanınızı çox almayaq, sistem sizi gözləyir.. Buyurun, <NavLink to="/register">Qeydiyyatdan keçin!</NavLink></p>
                </div>
            </div>
        </div >
    );
}
