import * as React from 'react';
import { Grid, List, Card, CardHeader, ListItemButton, ListItemText, ListItemIcon, Checkbox, Button, Divider } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
    return [...a, ...not(b, a)];
}

export default function SelectAllTransferList({ question, checkListEmpty, setSelectedListItems, answers }) {
    const [checked, setChecked] = React.useState([]);
    const [left, setLeft] = React.useState(question?.possibleAnswers);
    const [right, setRight] = React.useState([]);
    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);
    
    React.useEffect(() => {
        const isRightListEmpty = right.length !== 0;
        checkListEmpty(isRightListEmpty)
        setSelectedListItems(right, question?.keyword)

    }, [right])

    React.useEffect(() => {
        let selectedAnswers = answers?.find(answer => answer?.questionId == question?.nextId)?.[question?.keyword]
        if (selectedAnswers) {
            setRight(selectedAnswers)
            setLeft(question?.possibleAnswers?.filter((answer) => !selectedAnswers?.map((answer) => answer?.id).includes(answer?.id)))
        }
    }, [])

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = (items) => intersection(checked, items).length;

    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const items = [...right];
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setRight(items);
    };

    const customList = (title, items) => (
        <Card>
            <CardHeader
                sx={{ px: 2, py: 1 }}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={
                            numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
                        }
                        disabled={items.length === 0}
                        inputProps={{
                            'aria-label': 'bütün elementlər seçilib'
                        }}
                    />
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} seçili`}
            />
            <Divider />
            <List
                sx={{
                    height: 360,
                    bgcolor: 'background.paper',
                    overflow: 'auto',
                }}
                dense
                component="div"
                role="list"
            >
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided) => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                {items?.map((value, index) => (
                                    <Draggable key={value?.id?.toString()} draggableId={value?.id?.toString()} index={index}>
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <ListItemButton
                                                    key={value?.id}
                                                    role="listitem"
                                                    onClick={handleToggle(value)}
                                                    className='drag'
                                                >
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            checked={checked.indexOf(value) !== -1}
                                                            tabIndex={-1}
                                                            disableRipple
                                                            inputProps={{
                                                                'aria-labelledby': `transfer-list-all-item-${value?.id}-label`,
                                                                id: `${value?.id}`
                                                            }}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText id={`transfer-list-all-item-${value?.id}-label`} primary={`${value?.name}`} />
                                                </ListItemButton>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </List>
        </Card>
    );

    return (
        left &&
        <Grid container spacing={2} justifyContent="center" alignItems="center" className='mt-4 mx-0'>
            <Grid item className='col-5 p-0'>{customList(question?.keyword == 'universities' ? 'Universitetlər' : question?.keyword == 'specialities' && 'İxtisaslar', left)}</Grid>
            <Grid item className='col-2 p-0'>
                <Grid container direction="column" alignItems="center">
                    <Button
                        sx={{ my: 0.5 }}
                        variant="standart"
                        size="small"
                        onClick={handleCheckedRight}
                        disabled={leftChecked.length === 0 || (question?.maxLimit && (right.length + leftChecked.length > question?.maxLimit))}
                        aria-label="move selected right">&gt;</Button>
                    <Button
                        sx={{ my: 0.5 }}
                        variant="standart"
                        size="small"
                        onClick={handleCheckedLeft}
                        disabled={rightChecked.length === 0}
                        aria-label="move selected left">&lt;</Button>
                </Grid>
            </Grid>
            <Grid item className='col-5 p-0 draggable'>{customList('Seçdikləriniz', right)}</Grid>
        </Grid>
    );
}