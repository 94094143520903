import * as React from 'react';
import { BarChart, PieChart, pieArcLabelClasses } from '@mui/x-charts';

export default function HorizontalBars({ data }) {

    const highlightScope = {
        highlighted: 'series',
        faded: 'global',
    };

    const series = [
        data?.some(item => item?.entranceScore) ?
        {
            label: 'Keçid balı',
            data: data?.map(item => item?.entranceScore),
            color: "#3dd9eb"
        } : null,
        {
            label: 'Proqnoz balı',
            data: data?.map(item => item?.estimatedScore)
        }
    ].filter(series => series !== null).map((s) => ({ ...s, highlightScope }));

    return (
        <div className="barchart2 d-flex flex-column row-gap-3">
            {/* {data?.some(item => item?.entranceScore) && */}
            <BarChart
                height={400}
                series={series}
                yAxis={[{ scaleType: 'band', data: data.map(item => item.university)}]}
                xAxis={[{ label: 'Bal üzrə' }]}
                layout="horizontal"
                grid={{ vertical: true }} />

            <BarChart
                height={400}
                series={[{
                    label: 'Plan yerlərinin sayı',
                    data: data.map(item => item.planCount),
                    color: '#b800d8'
                }]}
                grid={{ horizontal: true }}
                xAxis={[
                    {
                        scaleType: 'band',
                        data: data.map(item => item.university),
                        label: 'Universitetlər'
                    },
                ]}
                yAxis={[
                    {
                        label: 'Plan yerlərinin sayı'
                    }
                ]}
                sx={{
                    [`.MuiChartsAxis-label`]: {
                        transform: 'translateX(-3px)'
                    }
                }} />
        </div>
    );
}