import React, { useState, useEffect } from 'react';
import { Checkbox, Button, TextField, Autocomplete, Chip } from '@mui/material';
import { request } from '../../../config';
import BarChart from './BarChart';
import TableStatistics from './TableStatistics';
import Loading from '../../../comps/Loading';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

export default function Statistics() {
    const [message, setMessage] = useState('');
    const [params, setParams] = useState();
    const [statistics, setStatistics] = useState();
    const [errorFields, setErrorFields] = useState();
    const [formData, setFormData] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getParams = async () => {
            try {
                const response = await request('user/v1/statistics-params', null, 'POST');

                if (response.code == "SUCCESS") {
                    setParams(response?.data)
                    setFormData({
                        type: response.data.types[1]?.id,
                        paymentType: response.data.paymentTypes[0]?.id,
                        // university: response.data.universities[0]?.id,
                        // speciality: response.data.specialities[0]?.id,
                        studyType: response.data.studyTypes[0]?.id,
                        yearInterval: response.data.yearIntervals[0]?.id,
                    })
                } else {
                    setMessage(response?.message ?? '')
                }

            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false)
            }
        }
        getParams();
    }, []);

    // const handleInvalid = (e, text) => {
    //     e.target.setCustomValidity(text); // Set custom validity message
    // };

    // const setValues = (e, message, minLength, maxLength, regex) => {
    //     const { name, value } = e.target;
    //     let isValid = true;

    //     // if ((regex && !regex.test(value)) || (minLength && value.length < minLength) || (maxLength && value.length > maxLength)) {
    //     //     isValid = false;
    //     // }

    //     message && e.target.setCustomValidity(isValid ? '' : message);

    //     setFormData({
    //         ...formData,
    //         [name]: value,
    //     });

    // };

    const handleSubmit = async (e) => {
        setLoading(true)
        try {
            const response = await request('user/v1/statistics', formData, 'POST');

            if (response.code == "SUCCESS") {
                setStatistics(response.data)
                setErrorFields(null)
            } else {
                setStatistics(null)
                setMessage(response.message ?? '')
                setErrorFields(response.data ?? null)
            }

        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false)
        }
    };

    const handleGetUniversities = async (specialityId) => {
        setLoading(true)
        try {
            const response = await request('user/v1/universities', { specialityId }, 'POST');

            if (response.code == "SUCCESS") {
                setParams({
                    ...params,
                    universities: response?.data ? [{ id: 999, name: "Hamısını seç" }, ...response?.data] : []
                });
                setMessage('')
            } else {
                setMessage(response.message ?? '')
            }

            setStatistics(null)

        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false)
        }
    }

    return (
        loading ? <Loading /> :
            <div className="col-11 d-flex flex-column gap-2 mx-auto animate__animated animate__fadeIn">
                {formData && params ? <>
                    <h5>Statistika</h5>
                    <form className='row row-gap-3 flex-wrap w-100 align-items-end' autoComplete="off" onSubmit={handleSubmit}>
                        <div className="col-12">
                            <Autocomplete
                                name='type'
                                className='col-12 col-lg-5'
                                options={params?.types}
                                getOptionLabel={(option) => option.name ?? ''}
                                value={params.types?.find(item => item.id == formData.type) || null}
                                onChange={(event, newValue) => {
                                    setFormData({
                                        ...formData,
                                        speciality: null,
                                        type: newValue?.id
                                    }), setStatistics(null)
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} error={errorFields?.type ? true : false} label="Statistika növü *" variant="standard" />
                                )} />
                        </div>

                        <Autocomplete
                            name='speciality'
                            className='col-12 col-lg-5'
                            options={params?.specialities}
                            getOptionLabel={(option) => option.name ?? ''}
                            value={params.specialities?.find(item => item.id == formData.speciality) || null}
                            onChange={(event, newValue) => (setFormData({
                                ...formData,
                                speciality: newValue?.id,
                                universities: formData.type == 1 ? [] : null
                            }), formData.type == 1 && handleGetUniversities(newValue?.id))}
                            renderInput={(params) => (
                                <TextField {...params} error={errorFields?.speciality ? true : false} label={formData.type == 1 ? "İxtisas *" : "İxtisas"} variant="standard" />
                            )} />

                        {formData.type == 1 && <>
                            <Autocomplete
                                name='paymentType'
                                className='col-12 col-lg-3'
                                options={params?.paymentTypes}
                                getOptionLabel={(option) => option.name ?? ''}
                                disableClearable
                                value={params.paymentTypes?.find(item => item.id == formData.paymentType) || null}
                                onChange={(event, newValue) => setFormData({
                                    ...formData,
                                    paymentType: newValue?.id,
                                })}
                                renderInput={(params) => (
                                    <TextField {...params} error={errorFields?.paymentType ? true : false} label="Ödəniş tipi" variant="standard" />
                                )} />
                            <Autocomplete
                                name='yearInterval'
                                className='col-12 col-lg-3'
                                options={params?.yearIntervals}
                                getOptionLabel={(option) => option.name ?? ''}
                                value={params.yearIntervals?.find(item => item.id == formData.yearInterval) || null}
                                onChange={(event, newValue) => setFormData({
                                    ...formData,
                                    yearInterval: newValue?.id,
                                })}
                                renderInput={(params) => (
                                    <TextField {...params} error={errorFields?.yearInterval ? true : false} label="Qəbul ili" variant="standard" />
                                )} /></>}

                        {formData.type == 1 && formData.speciality && params.universities &&
                            <Autocomplete
                                multiple
                                name='universities'
                                disableCloseOnSelect
                                disableListWrap
                                className='col-12 col-lg-5'
                                options={params?.universities}
                                getOptionLabel={(option) => option.name ?? ''}
                                value={params.universities?.filter(option => formData.universities?.includes(option.id)) || []}
                                onChange={(event, newValue, reason, details) =>
                                    setFormData({
                                        ...formData,
                                        universities: details?.option?.id == 999 ? (newValue.some(item => item.id == 999) ? params?.universities.map(item => item.id) : []) : newValue?.filter(item => item.id != 999).map(item => item?.id),
                                    })}
                                renderInput={(params) => (
                                    <TextField {...params} error={errorFields?.universities ? true : false} label={"Universitetlər *"} variant="standard" />
                                )}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option?.name}
                                    </li>
                                )}
                                renderTags={(tags, getTagProps) =>
                                    tags?.filter(item => item?.id != 999).map((item, index) => (
                                        <Chip
                                            variant="solid"
                                            {...getTagProps({ index })}
                                            label={item?.name}
                                        />
                                    ))
                                }
                            />}

                        {formData.type == 3 && <>
                            <Autocomplete
                                multiple
                                // limitTags={1}
                                name='cities'
                                disableCloseOnSelect
                                disableListWrap
                                className='col-12 col-lg-6'
                                options={params?.cities}
                                getOptionLabel={(option) => option.name ?? ''}
                                value={params.cities?.filter(option => formData.cities?.includes(option.id)) || []}
                                onChange={(event, newValue) =>
                                    setFormData({
                                        ...formData,
                                        cities: newValue.map(item => item.id),
                                    })}
                                renderInput={(params) => (
                                    <TextField {...params} error={errorFields?.cities ? true : false} label="Şəhərlər" variant="standard" />
                                )} />

                            <div className="col-12 col-lg-3">
                                <TextField
                                    name='minScore'
                                    error={errorFields?.minScore ? true : false}
                                    value={formData.minScore ?? ''}
                                    inputProps={{ maxLength: 6 }}
                                    onChange={e => (/^(?:(?!0)\d{0,3}(?:\.\d{0,2})?|700(?:\.0{0,2})?)?$/).test(e.target.value) && setFormData({
                                        ...formData,
                                        minScore: e.target.value,
                                    })}
                                    label="Minimum bal" variant="standard" type='string' />
                            </div>

                            <div className="col-12 col-lg-3">
                                <TextField
                                    name='maxScore'
                                    error={errorFields?.maxScore ? true : false}
                                    value={formData.maxScore ?? ''}
                                    inputProps={{ maxLength: 6 }}
                                    onChange={e => (/^(?:(?!0)\d{0,3}(?:\.\d{0,2})?|700(?:\.0{0,2})?)?$/).test(e.target.value) && setFormData({
                                        ...formData,
                                        maxScore: e.target.value,
                                    })}
                                    label="Maksimum bal" variant="standard" type='string' />
                            </div></>}

                        <Autocomplete
                            name='studyType'
                            className='col-12 col-lg-3'
                            options={params?.studyTypes}
                            getOptionLabel={(option) => option.name ?? ''}
                            disableClearable
                            value={params.studyTypes?.find(item => item.id == formData.studyType) || null}
                            onChange={(event, newValue) => setFormData({
                                ...formData,
                                studyType: newValue?.id,
                            })}
                            renderInput={(params) => (
                                <TextField {...params} error={errorFields?.studyType ? true : false} label="Təhsil forması" variant="standard" />
                            )} />

                        <Autocomplete
                            multiple
                            name='languages'
                            className='col-12 col-lg-4'
                            disableCloseOnSelect
                            options={params?.languages}
                            getOptionLabel={(option) => option.name ?? ''}
                            disableClearable
                            value={params.languages?.filter(item => formData.languages?.includes(item?.id)) || []}
                            onChange={(event, newValue) => setFormData({
                                ...formData,
                                languages: newValue.map(item => item?.id),
                            })}
                            renderInput={(params) => (
                                <TextField {...params} error={errorFields?.languages ? true : false} label="Tədris dili" variant="standard" />
                            )} />

                        <div className="d-flex flex-wrap flex-column gap-3 align-items-center col-12">
                            <Button type='submit' variant="contained" className='white p-2 mx-auto col-3'>Axtar</Button>
                            {message && !statistics && <p className="error-message mx-auto">{message}</p>}
                        </div>
                    </form>
                    {statistics && (formData.type == 1 ? <BarChart data={statistics} /> : formData.type == 3 && <TableStatistics data={statistics} />)}
                </> : <h5 className='text-center'>{message}</h5>}
            </div>
    );
};