import React, { useState, useEffect } from 'react';
import { Pagination, Button, StepLabel, Typography, Box, TextField, Autocomplete, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { request } from '../../../config';
import { NavLink, useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import Loading from '../../../comps/Loading';

export default function Appeals() {
    const [message, setMessage] = useState('');
    const [talentScore, setTalentScore] = useState(false);
    const [loading, setLoading] = useState(true);
    const [pageNumber, setPageNumber] = useState(1);
    const [appeals, setAppeals] = useState();

    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            try {
                const response = await request('user/v1/appeals', { pageNumber }, 'POST');

                if (response.code === "SUCCESS") {
                    setAppeals(response?.data);
                    response?.data?.appeals?.some(item => item?.talentScore !== null) && setTalentScore(true)
                } else {
                    setMessage(response?.message ?? '')
                }

            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false)
            }
        })();
    }, [pageNumber])

    return (
        loading ? <Loading /> :
            <div className="col-10 col-lg-8 col-xl-7 mx-auto animate__animated animate__fadeIn menus animate__faster" style={{ borderRight: 'none' }}>
                {appeals ?
                    <><div className="d-flex flex-wrap justify-content-between align-items-center row-gap-3">
                        <h5 className='m-0'>Müraciətlərim:</h5>
                        <Button variant="contained" className='white py-2 px-3' onClick={() => navigate('quiz')} ><AddIcon fontSize="small" /> Yeni müraciət</Button>
                    </div>
                    {appeals?.appeals && <div className='d-flex flex-column align-items-center animate__animated animate__fadeIn animate__faster'>
                        <TableContainer component={Paper} className="d-flex flex-column my-3 gap-1 appeals-table">
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Tarix</TableCell>
                                        <TableCell>Qrup</TableCell>
                                        <TableCell>Alt qrup</TableCell>
                                        <TableCell>Bal</TableCell>
                                        {talentScore && <TableCell>İstedad balı</TableCell>}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {appeals?.appeals?.map((row) => (
                                        <TableRow
                                            key={row?.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            onClick={() => navigate(`result/${row?.id}`)}
                                        >
                                            <TableCell>{row?.dataDate}</TableCell>
                                            <TableCell>{row?.group}</TableCell>
                                            <TableCell>{row?.subGroup}</TableCell>
                                            <TableCell>{row?.score}</TableCell>
                                            {talentScore && <TableCell>{row?.talentScore}</TableCell>}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Pagination count={appeals?.totalPage} color="secondary"
                            page={pageNumber}
                            onChange={(e, value) => setPageNumber(value)} /></div>}</>
                    : <h6 className='mt-5 text-center'>{message}</h6>}
            </div>
    );
};