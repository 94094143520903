import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tooltip, IconButton, TableSortLabel } from '@mui/material';
import { Modal, ModalClose, ModalDialog, DialogTitle, DialogContent } from '@mui/joy';
import { BarChart, PieChart, pieArcLabelClasses } from '@mui/x-charts';
import { VisibilityOffOutlined, VisibilityOutlined, ExpandMore } from '@mui/icons-material';
import { request } from '../../../config';
import InfoIcon from '@mui/icons-material/Info';

export default function TableStatistics({ data }) {
    const [details, setDetails] = useState()
    const [orderBy, setOrderBy] = useState('');
    const [order, setOrder] = useState('asc');

    const handleSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleGetDetails = async (id) => {
        try {
            const response = await request(`user/v1/statistics/${id}`, null, 'POST');

            setDetails(response.data ? {
                ...response.data, details: [
                    { title: "İxtisas", value: response.data?.specialityName },
                    { title: "Universitet", value: response.data?.details?.universityName },
                    { title: "Qrup", value: response.data?.details?.group },
                    { title: "Altqrup", value: response.data?.details?.subGroup },
                    { title: "Şəhər", value: response.data?.details?.cityName },
                    { title: "Təhsil forması", value: response.data?.details?.studyType },
                    { title: "Tədris dili", value: response.data?.details?.studyLanguage },
                    { title: "Ödəniş", value: response.data?.details?.annualPayment }
                ]
            } : null);

        } catch (error) {
            console.error(error);
        }
    }

    return (
        data && <>
            <TableContainer component={Paper} className="d-flex flex-column my-3 gap-1 appeals-table with-details">
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'specialityName'}
                                    direction={orderBy === 'specialityName' ? order : 'asc'}
                                    onClick={() => handleSort('specialityName')}>İxtisas</TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'universityName'}
                                    direction={orderBy === 'universityName' ? order : 'asc'}
                                    onClick={() => handleSort('universityName')}>Universitet</TableSortLabel>
                            </TableCell>
                            <TableCell sx={{ textWrap: 'nowrap' }}>
                                <TableSortLabel
                                    active={orderBy === 'entranceScore'}
                                    direction={orderBy === 'entranceScore' ? order : 'asc'}
                                    onClick={() => handleSort('entranceScore')}>
                                    Keçid balı
                                    <Tooltip enterTouchDelay title="Mötərizə içərisində müvafiq ixtisas üzrə dövlət sifarişi əsasında qəbulun keçid balı göstərilmişdir. (-) işarəsi isə göstərir ki, müvafiq ixtisasda dövlət sifarişi əsasında təhsil almaq hüququ qazanmış abituriyent yoxdur. Təhsili yalnız ödənişli əsaslarla olan ixtisasların qarşısında belə mötərizə göstərilməmişdir." arrow>
                                        <IconButton>
                                            <InfoIcon fontSize='small' />
                                        </IconButton>
                                    </Tooltip>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'cityName'}
                                    direction={orderBy === 'cityName' ? order : 'asc'}
                                    onClick={() => handleSort('cityName')}>Şəhər</TableSortLabel>
                            </TableCell>
                            <TableCell><TableSortLabel
                                active={orderBy === 'studyType'}
                                direction={orderBy === 'studyType' ? order : 'asc'}
                                onClick={() => handleSort('studyType')}>Təhsil forması</TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.sort((a, b) => {
                            if (order === 'asc') {
                                return a[orderBy]?.localeCompare(b[orderBy], 'az');
                            } else {
                                return b[orderBy]?.localeCompare(a[orderBy], 'az');
                            }
                        }).map((row) => (
                            <TableRow
                                key={row?.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                onClick={() => handleGetDetails(row?.id)}
                            >
                                {/* <TableCell>
                                    <IconButton>
                                        <VisibilityOutlined />
                                    </IconButton>
                                </TableCell> */}
                                <TableCell>{row?.specialityName}</TableCell>
                                <TableCell>{row?.universityName}</TableCell>
                                <TableCell>{row?.entranceScore}</TableCell>
                                <TableCell>{row?.cityName}</TableCell>
                                <TableCell>{row?.studyType}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Modal open={details ? true : false} onClose={() => setDetails(null)}>
                <ModalDialog variant="plain" className="text-center p-5 gap-3 col-12 col-lg-6 col-md-8 animate__animated animate__fadeIn">
                    <ModalClose variant="plain" sx={{ m: 1 }} onClick={() => setDetails(null)} />
                    <div className="text-start fw-500">
                        {details?.details?.map((item, index) => (
                            item?.value && (
                                <div key={index}>
                                    {item?.title}: {item?.value}
                                </div>)))}
                    </div>
                    <BarChart
                        height={400}
                        className='barchart2'
                        series={[{
                            label: 'Dövlət sifarişli',
                            data: details?.scoreHistory?.map(item => item.stateAccessScore)
                        }, {
                            label: 'Ödənişli',
                            data: details?.scoreHistory?.map(item => item.paidAccessScore)
                        }]}
                        grid={{ horizontal: true }}
                        xAxis={[
                            {
                                scaleType: 'band',
                                data: details?.scoreHistory?.map(item => item.year),
                                label: 'Qəbul illəri'
                            },
                        ]}
                        yAxis={[
                            {
                                label: 'Bal üzrə'
                            }
                        ]}
                        sx={{
                            [`.MuiChartsAxis-label`]: {
                                transform: 'translateX(-10px)'
                            }
                        }}
                    />
                </ModalDialog>
            </Modal>
        </>
    );
};