import * as React from 'react';
import { Grid, List, Card, CardHeader, ListItemButton, ListItemText, ListItemIcon, Checkbox, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function CustomizedAccordions({ question, checkListEmpty, setSelectedListItems, answers, handleGetSpecialitiesForUniversity }) {
  const [expanded, setExpanded] = React.useState();
  const [checked, setChecked] = React.useState([]);

  const handleChange = (panel, id) => (event, newExpanded) => {
    newExpanded ? handleGetSpecialitiesForUniversity(id) : false;
    setExpanded(newExpanded ? panel : false);
  };

  const handleToggle = (value, item) => () => {
    const isChecked = checked.some((checkedItem) => checkedItem.id === value.id);
    if (isChecked) {
      const newChecked = checked.filter((checkedItem) => checkedItem.id !== value.id);
      setChecked(newChecked);
    } else if (checked.length < question?.maxLimit) {
      setChecked([...checked, { ...value, uni: item?.name, universityId: item?.id }]);
    }
  };

  React.useEffect(() => {
    const isListEmpty = checked.length !== 0;
    checkListEmpty(isListEmpty)
    setSelectedListItems(checked, question?.keyword)
  }, [checked])


  React.useEffect(() => {
    let selectedAnswers = answers?.find(answer => answer?.questionId === question?.nextId)?.[question?.keyword];
    if (selectedAnswers) {
      const selectedSpecialities = selectedAnswers.reduce((acc, curr) => {
        if (curr.uni && curr.id) {
          return [...acc, { id: curr.id, name: curr.name, uni: curr.uni }];
        }
        return acc;
      }, []);
      setChecked(selectedSpecialities);
    }
  }, []);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = [...checked];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setChecked(items);
  };


  return (
    <Grid container spacing={2} alignItems="start" className='row mt-4 mx-0 justify-content-between'>
      <Grid item className='col-6 p-0'>
        {question?.possibleAnswers && question?.possibleAnswers.map((item, index) =>
          <Accordion expanded={expanded === index} onChange={handleChange(index, item?.id)}>
            <AccordionSummary>
              <Typography>{item.name}</Typography>
            </AccordionSummary>
            <AccordionDetails className='p-0'>
              {item?.specialities?.map((speciality, index) =>
                <ListItemButton
                  key={speciality?.id}
                  role="listitem"
                  onClick={handleToggle(speciality, item)}
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={checked.some((checkedItem) => checkedItem.id === speciality.id)}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{
                        'aria-labelledby': index,
                        id: `${item?.id}-${speciality?.id}`
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText id={index} primary={`${speciality?.name}`} />
                </ListItemButton>
              )}</AccordionDetails>


          </Accordion>
        )}
      </Grid>

      <Grid item className='col-6 draggable'>
        <Card>
          <CardHeader
            sx={{ px: 2, py: 1 }}
            title={"Seçdikləriniz"}
            subheader={`${checked.length} seçili`} />
          <Divider />
          <List
            sx={{
              height: 500,
              overflow: 'auto',
              padding: '1rem'
            }}
            dense
            component="div"
            role="list" >
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {checked?.map((value, index) => (
                      <Draggable key={value.id.toString()} draggableId={value.id.toString()} index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <ListItemButton className='drag'>
                              <ListItemText primary={`${index + 1}. ${value?.name} / ${value?.uni}`} />
                            </ListItemButton>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </List>
        </Card>
      </Grid>
    </Grid>
  );
}
