import React from 'react';
import './Comps.scss';

export default function Loading() {
    return (
        <>
            {/* <div className="loading-overlay">
            </div> */}
            <div className="spinner"></div>
        </>
    )
};
