import React, { useState, useEffect } from 'react';
import { useNavigate, NavLink, Link, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import "../../App.scss";
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { Button, TextField } from '@mui/material';
import { Modal, ModalClose, ModalDialog, DialogTitle } from '@mui/joy';
import logo from "../Home/img/appLogo.svg";
import { request } from '../../config';

function Register() {
    const [showPassword, setShowPassword] = useState(false);
    const [showRepeatPassword, setShowRepeatPassword] = useState(false);
    const [open, setOpen] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [message, setMessage] = useState();
    const [success, setSuccess] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
    });

    const navigate = useNavigate();
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');

    useEffect(() => {
        const handleVerify = async () => {
            if (token) {
                try {
                    const response = await request('auth/v1/verify-email', { token: token }, 'POST');

                    if (response.code == "REGISTRATION_SUCCESS") {
                        setSuccess(true)
                    }
                    setOpenSuccess(true)
                    setMessage(response?.message ?? '')

                } catch (error) {
                    console.error(error);
                }
            }
        }
        handleVerify();
    }, [token]);

    const confirmEmailAgain = async () => {
        if (token) {
            try {
                const response = await request('auth/v1/send-confirmation-email', { token: token }, 'POST');

                setOpenSuccess(false)
                setMessage(response.message ?? '')
                setOpen(true); 

            } catch (error) {
                console.error(error);
            }
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true)
        try {
            const response = await request('auth/v1/register', formData, 'POST');

            if (response.code == "SEND_EMAIL_SUCCESS") {
                setOpen(true);
            }
            setMessage(response.message ?? '')

        } catch (error) {
            console.error(error);
        } finally {
            setIsSubmitting(false)
        }
    };

    const handleInvalid = (e, text) => {
        e.target.setCustomValidity(text);
    };

    const setValues = (e, message, minLength, maxLength, regex) => {
        const { name, value } = e.target;
        let isValid = true;

        if ((regex && !regex.test(value)) || (minLength && value.length < minLength) || (maxLength && value.length > maxLength) || (name === 'confirmPassword' && value !== formData.password)) {
            isValid = false;
        }

        e.target.setCustomValidity(isValid ? '' : message);

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <div className="d-flex align-items-center justify-content-center text-center login animate__animated animate__slideInUp">
            {!openSuccess && !open &&
                <div className="row justify-content-center col-10 col-lg-6 gap-5 p-5 ">
                    <div className="d-flex align-items-center justify-content-center animate__animated animate__slideInUp animate__slow">
                        <img src={logo} alt="logo picture here" />
                        <h3 className='m-0'>Qeydiyyat</h3>
                    </div>
                    <form onSubmit={handleSubmit} className='d-flex row-gap-3 flex-column'>
                        <TextField required label="İstifadəçi adı" inputProps={{ maxLength: 100, minLength: 3 }} variant="standard" type='text' name="name" value={formData.name}
                            onInvalid={(e) => handleInvalid(e, 'İstifadəçi adı daxil edin (minimum 3 simvol)')}
                            onChange={(e) => setValues(e, 'İstifadəçi adı daxil edin (minimum 3 simvol)', 3, 100)} />

                        <TextField required label="E-mail" inputProps={{ maxLength: 250, minLength: 8 }} variant="standard" type='email' name="email" value={formData.email}
                            onInvalid={(e) => handleInvalid(e, 'Düzgün email formatı daxil edin')}
                            onChange={(e) => setValues(e, 'Düzgün email formatı daxil edin', 8, 250, /^[^\s@]+@[^\s@]+\.[^\s@]+$/)} />

                        <TextField required name="password" inputProps={{ maxLength: 24, minLength: 8 }} value={formData.password} InputProps={{ endAdornment: showPassword ? <VisibilityOutlined onClick={() => setShowPassword((show) => !show)} /> : <VisibilityOffOutlined onClick={() => setShowPassword((show) => !show)} /> }} label="Şifrə" variant="standard" type={showPassword ? "text" : "password"}
                            onInvalid={(e) => handleInvalid(e, '• Ən az 1 böyük hərf. \n• Ən az 1 xüsusi simvol. \n• Ən az 1 kiçik hərf. \n• Ən az 1 rəqəm. \n• Ən az 8 simvol.')}
                            onChange={(e) => setValues(e, '• Ən az 1 böyük hərf. \n• Ən az 1 xüsusi simvol. \n• Ən az 1 kiçik hərf. \n• Ən az 1 rəqəm. \n• Ən az 8 simvol.', 8, 24, /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#&()–\[\]{}:;',?/*~$^+=<>]).{8,24}$/)} />

                        <TextField required name="confirmPassword" inputProps={{ maxLength: 24 }} value={formData.confirmPassword} InputProps={{ endAdornment: showRepeatPassword ? <VisibilityOutlined onClick={() => setShowRepeatPassword((show) => !show)} /> : <VisibilityOffOutlined onClick={() => setShowRepeatPassword((show) => !show)} /> }} label="Şifrəni təkrarlayın" variant="standard" type={showRepeatPassword ? "text" : "password"}
                            onInvalid={(e) => handleInvalid(e, 'Şifrələr uyğun gəlmir')}
                            onChange={(e) => setValues(e, 'Şifrələr uyğun gəlmir')} />

                        {message && !open && !openSuccess && <p className="error-message">{message}</p>}

                        <Button type='submit' disabled={isSubmitting} variant="contained" className='white p-3'>Qeydiyyat</Button>
                        <p>Hesabınız var? <NavLink to="/login" className="nav-link">Daxil olun</NavLink></p>
                    </form>
                </div>
            }

            <Modal open={open} onClose={() => navigate('/login')}>
                <ModalDialog variant="plain" className="text-center p-5">
                    <ModalClose variant="plain" sx={{ m: 1 }} onClick={() => navigate('/login')} />
                    <DialogTitle>{message}</DialogTitle>
                </ModalDialog>
            </Modal>

            <Modal open={openSuccess}>
                <ModalDialog variant="plain" className="text-center p-5" size="lg">
                    {success ?
                        <svg width="56" height="56" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" className='m-auto'>
                            <path d="M17.4 30L25.8 38.4L42.6 21.6M58 30C58 45.464 45.464 58 30 58C14.536 58 2 45.464 2 30C2 14.536 14.536 2 30 2C45.464 2 58 14.536 58 30Z" stroke="#06C7BB" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        :
                        <svg width="56" height="56" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" className='m-auto'>
                            <path d="M40.9602 21.1612C41.5459 20.5754 41.5459 19.6256 40.9602 19.0398C40.3744 18.4541 39.4246 18.4541 38.8388 19.0398L40.9602 21.1612ZM19.0398 38.8388C18.4541 39.4246 18.4541 40.3744 19.0398 40.9602C19.6256 41.5459 20.5754 41.5459 21.1612 40.9602L19.0398 38.8388ZM38.8388 40.9602C39.4246 41.5459 40.3744 41.5459 40.9602 40.9602C41.5459 40.3744 41.5459 39.4246 40.9602 38.8388L38.8388 40.9602ZM21.1612 19.0398C20.5754 18.4541 19.6256 18.4541 19.0398 19.0398C18.4541 19.6256 18.4541 20.5754 19.0398 21.1612L21.1612 19.0398ZM56.5 30C56.5 44.6355 44.6355 56.5 30 56.5V59.5C46.2924 59.5 59.5 46.2924 59.5 30H56.5ZM30 56.5C15.3645 56.5 3.5 44.6355 3.5 30H0.5C0.5 46.2924 13.7076 59.5 30 59.5V56.5ZM3.5 30C3.5 15.3645 15.3645 3.5 30 3.5V0.5C13.7076 0.5 0.5 13.7076 0.5 30H3.5ZM30 3.5C44.6355 3.5 56.5 15.3645 56.5 30H59.5C59.5 13.7076 46.2924 0.5 30 0.5V3.5ZM38.8388 19.0398L28.9393 28.9393L31.0607 31.0607L40.9602 21.1612L38.8388 19.0398ZM28.9393 28.9393L19.0398 38.8388L21.1612 40.9602L31.0607 31.0607L28.9393 28.9393ZM40.9602 38.8388L31.0607 28.9393L28.9393 31.0607L38.8388 40.9602L40.9602 38.8388ZM31.0607 28.9393L21.1612 19.0398L19.0398 21.1612L28.9393 31.0607L31.0607 28.9393Z" fill="#FF1058" />
                        </svg>}
                    <DialogTitle className="text-center m-auto">{message}</DialogTitle>
                    {success ?
                        <Button variant="contained" type='button' className='blue w-100 p-3' onClick={() => navigate("/login")}>
                            Daxil ol
                        </Button>
                        : <>
                            <Button variant="contained" className='white w-100 p-3' onClick={confirmEmailAgain}>Yenidən göndər</Button>
                            <Button variant="contained" className='loginBtn w-100' onClick={() => navigate("/")}>Ana səhifə</Button>
                        </>
                    }
                </ModalDialog>
            </Modal>
        </div >
    );
}

export default Register;