import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import "../../../App.scss";
import logo from "../../Home/img/appLogo.svg";

export default function Menu({ steps }) {
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.clear()
        navigate('/login')
    };

    return (
        <ul className="menus d-flex flex-column row-gap-2 col-12 col-md-3 px-3 animate__animated animate__slideInLeft">
            {steps && <>
                <li>
                    <h6 className='ps-2'>{JSON.parse(localStorage.getItem('loginData'))?.personalInfo?.name}</h6>
                </li>
                <hr />
                {steps.map((step, index) => {
                    return (
                        <li key={step.path}>
                            <NavLink to={`/account${step.path}`}>
                                {step.name}
                            </NavLink>
                        </li>
                    );
                })}
            </>
            }
        </ul>
    );
}
