import React, { useState, useEffect } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import "../../App.scss";
import { Button } from '@mui/material';
import logo from "./img/appLogo.svg";
import pic from "./img/extra_large_fb09cde2c3_1527157043.jpg";
import 'animate.css';

export default function Home() {
  const navigate = useNavigate();
  const userType = localStorage.getItem('userType')

  const text = "";

  return (
    // <div className='d-flex align-items-center'>
      <div className="d-flex flex-wrap align-items-center justify-content-between row-gap-2">
        <div className="d-flex flex-column align-items-center text-center col-12 col-md-7 col-xl-7 gap-3">
          <h1 className='col-10 animate__animated animate__slideInLeft m-0'>“Dim<span className='link'>Ekspert</span>” portalına xoş gəlmişsiniz</h1>
          <p className='col-10 animate__animated animate__slideInLeft  m-0'>Bu sistem universitetlərə qəbul imtahanlarında iştirak etmiş, hər hansı bal toplamış və ixtisas seçimi mərhələsində data analizi əsasında müəyyən tövsiyələr əldə etmək istəyən abituriyentlərə dəstək məqsədi ilə yaradılmışdır. Sistemdə <NavLink to="/register" className="link">qeydiyyatdan keçmək</NavLink>, sistemə <NavLink to="/login" className="link">daxil olmaqla</NavLink> data analizi əsasında formalaşmış ehtimal və tövsiyələri almaq imkanından faydalana bilərsiz...
            <NavLink to="/about" className="link">Ətraflı</NavLink>
          </p></div>
        <div className="col-md-5 col-xl-5 d-none d-md-block">
          {/* <img className='animate__animated animate__slideInRight' src={logo} alt="" width="100%" height="100%" /> */}
          <img className='home_pic animate__animated animate__slideInRight' src={pic} alt="picture here" width="100%" height="100%" />
        </div>
      </div>
    // </div>
  );
}
