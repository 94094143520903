import React from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import { Button } from '@mui/material';

function NotFound() {
  const navigate = useNavigate();

  return (
    <div className="d-flex align-items-center justify-content-center text-center login">
      <div className="row justify-content-center col-10 gap-3 p-5 animate__animated animate__slideInUp">
        <h1>404</h1>
        <h5>Səhifə tapılmadı</h5>
        <Button variant="contained" className='white w-100 p-3' onClick={() => navigate('/')}>
          Ana səhifə
        </Button>
      </div>
    </div >
  );
}



export default NotFound;
