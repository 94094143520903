const BASE_URL = process.env.REACT_APP_API_ENDPOINT;

export const request = async (url, data, method) => {
    try {
        const response = await fetch(`${BASE_URL}${url}`, {
            method: method,
            headers: {
                "Accept-Language": 'az',
                "Content-Type": "application/json",
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            },
            ...(method === 'POST' && { body: JSON.stringify(data) })
        });

        const responseData = await response.json();

        if (responseData.code === "SESSION_IS_EXPIRED") {
            localStorage.clear();
            window.location.href = '/login';
        }

        return responseData;

    } catch (error) {
        console.error(error);
        throw error;
    }
};