import React, { useState, useEffect } from 'react';
import { Button, Stepper, Step, StepLabel, Typography, Box, TextField, Autocomplete } from '@mui/material';
import TransferList from './TransferList';
import Accordion from './Accordion';
import { request } from '../../../config';
import { useNavigate } from 'react-router-dom';
import Loading from '../../../comps/Loading';

export default function Quiz() {
    const [message, setMessage] = useState('');
    const [question, setQuestion] = useState();
    const [answers, setAnswers] = useState([]);
    const [activeStep, setActiveStep] = useState(0);
    const [stepCount, setStepCount] = useState();
    const [listEmpty, setListEmpty] = useState(false);
    const [loading, setLoading] = useState();
    const [skipped, setSkipped] = useState(new Set());

    const navigate = useNavigate();

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
        handleGetQuestion(answers?.find(answer => answer?.questionId === question?.nextId))
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        handleGetQuestion(answers[activeStep - 2])
    };

    // const handleSkip = () => {
    //     if (!isStepOptional(activeStep)) {
    //         // You probably want to guard against something like this,
    //         // it should never occur unless someone's actively trying to break something.
    //         throw new Error("You can't skip a step that isn't optional.");
    //     }

    //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
    //     setSkipped((prevSkipped) => {
    //         const newSkipped = new Set(prevSkipped.values());
    //         newSkipped.add(activeStep);
    //         return newSkipped;
    //     });
    // };

    const handleReset = () => {
        setQuestion(null)
        setActiveStep(0);
        setAnswers([])
        handleGetQuestion()
    };

    const handleGetQuestion = async (body) => {
        setLoading(true)
        try {
            const response = await request('user/v1/question', { ...body }, 'POST');

            if (response.code == "SUCCESS") {
                if (response?.data?.question?.hasNext) {
                    setQuestion(response?.data?.question)
                    setListEmpty(false)
                }
                else {
                    setQuestion(null)
                    setStepCount(null)
                    handleGetResult()
                }
                !body && setStepCount(response?.data?.totalQuestionCount);
            }
            else {
                setQuestion(null)
                setStepCount(null)
                setMessage(response?.message ?? '')
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false)
        }
    };

    const handleGetResult = async () => {
        try {
            const response = await request('user/v1/find-specialities', {
                answers
                // answers: [
                //     { questionId: 1, "answerId": 1 }, { questionId: 2, "answerId": 4 }, { questionId: 3, "answerId": 9 }, { questionId: 4, answer: null }, { questionId: 5, "answerId": 13 }, { questionId: 6, answer: null }, { questionId: 9, "answerId": 19 }, { questionId: 10, "answerId": 23 }, { questionId: 11, "answerId": 29 }, { questionId: 12, answer: "2500" }, { questionId: 13, "answerId": 25 }
                // ]
            }, 'POST');
            if (response.code == "SUCCESS") {
                navigate(`/account/appeals/result/${response?.data?.id}`)
            }
            else {
                setMessage(response?.message ?? '')
            }
            // NO_SPECIALITIES_FOUND
        } catch (error) {
            console.error(error);
        }
    };

    const handleGetSpecialitiesForUniversity = async (universityId) => {
        if (!question.possibleAnswers.find(item => item?.id === universityId).specialities) {
            try {
                const response = await request('user/v1/specialities', { universityId }, 'POST');
                if (response.code == "SUCCESS") {
                    const updatedQuestion = { ...question };
                    updatedQuestion.possibleAnswers.find(item => item?.id === universityId).specialities = response.data || [];
                    setQuestion(updatedQuestion);
                }
                else {
                    setMessage(response?.message ?? '')
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    useEffect(() => {
        handleGetQuestion()
    }, [])

    const handleCollectAnswers = (newValue, questionId, answer, list, listName) => {

        const answerId = newValue?.id
        const updatedAnswers = answers.map(item => (
            item.questionId === questionId ? { ...item, answerId, [listName]: list, answer } : item
        ));

        setAnswers(
            updatedAnswers.some(answer => answer.questionId === questionId)
                ? updatedAnswers
                : [...updatedAnswers, { questionId, answerId, [listName]: list, answer }]
        );

    };

    const getValueForQuestion = (questionId) => {
        const answer = answers.find(answer => answer.questionId === questionId);
        return answer ? question?.possibleAnswers?.find(option => option?.id === answer?.answerId) : null;
    };

    return (
        stepCount ?
            <div className="col-11 mx-auto animate__animated animate__fadeIn">
                <Stepper activeStep={activeStep} sx={{ size: 'lg' }} className='stepper flex-wrap'>
                    {Array.from({ length: stepCount }, (_, index) => {
                        const stepProps = {};

                        if (isStepSkipped(index))
                            stepProps.completed = false;

                        return (
                            <Step key={index} {...stepProps}>
                                <StepLabel></StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                {loading ? <Loading /> :
                    <div className="animate__animated animate__fadeIn" >
                        <Typography variant="h6" sx={{ mb: 2, mt: 4 }}>{question?.content}</Typography>
                        {question?.choiceType == 2
                            ? <Autocomplete
                                name='answerId'
                                options={question?.possibleAnswers}
                                getOptionLabel={(option) => option.content ?? ''}
                                value={getValueForQuestion(question?.nextId) || null}
                                onChange={(e, newValue) => handleCollectAnswers(newValue, question?.nextId)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        placeholder='Ən uyğun cavabı seçin' />
                                )} />
                            : question?.keyword == "universityAndSpecialities" ?
                                <Accordion question={question} answers={answers}
                                    setSelectedListItems={(list, listName) => handleCollectAnswers(null, question?.nextId, null, list, listName)}
                                    handleGetSpecialitiesForUniversity={(universityId) => handleGetSpecialitiesForUniversity(universityId)}
                                    checkListEmpty={isEmpty => setListEmpty(isEmpty)} />
                                : question?.choiceType == 3 ?
                                    <TransferList
                                        question={question}
                                        answers={answers}
                                        setSelectedListItems={(list, listName) => handleCollectAnswers(null, question?.nextId, null, list, listName)}
                                        checkListEmpty={isEmpty => setListEmpty(isEmpty)} />
                                    : question?.choiceType == 4 &&
                                    <TextField
                                        name='score'
                                        className='col-12'
                                        required
                                        value={answers?.find(answer => answer?.questionId == question?.nextId)?.answer || ''}
                                        inputProps={{ maxLength: 12 }}
                                        // onInvalid={(e) => handleInvalid(e, 'Balı daxil edin')}
                                        onChange={e => e.target.value.length < 12 && handleCollectAnswers(null, question?.nextId, e.target.value)}
                                        label="Məbləğ" variant="standard" type='number' />
                        }
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Button
                                color="inherit"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ mr: 1 }} >
                                Geri
                            </Button>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button type='button' onClick={handleNext} sx={{ color: '#8b3dff', ":hover": { backgroundColor: '#8b3dff17' } }}
                                disabled={(answers?.some(answer => (answer?.questionId === question?.nextId) && (answer?.answerId || answer?.answer)) || listEmpty) ? false : true}
                            >
                                {activeStep === stepCount - 1 ? 'Bitir' : 'İrəli'}
                            </Button>
                        </Box>
                    </div>}
            </div> : message && <div className="col-11 my-5 mx-auto text-center">
                <h5>{message}</h5>
                <Button className='white mt-2' variant='contained' onClick={handleReset}>Yenidən yoxla</Button>
            </div>
    );
};