import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import "../../App.scss";
import 'animate.css';
import logo from "../Home/img/appLogo.svg";
import { NavLink } from 'react-router-dom';

export default function Contact() {
    return (
            <div className="d-flex align-items-center justify-content-center text-center login animate__animated animate__slideInUp">
            <div className="row justify-content-center col-10 gap-3 p-5">
              <h2>Əlaqə</h2>
              <p>Bizlərlə <NavLink to="mailto:info@dimekspert.az">info@dimekspert.az</NavLink> e-poçt adresi vasitəsi ilə əlaqə saxlaya bilərsiz. İstənilən sual, təklif, müraciətiniz çox qısa müddət ərzində tərəfimizdən cavablanacaqdır.</p>
            </div>
          </div >
    );
}
