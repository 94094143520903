import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, Navigate, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import Info from './Info/Info';
import Appeals from './Appeals/Appeals';
import Statistics from './Statistics/Statistics';
import Menu from './Menu/Menu';
import "../../App.scss";
import Quiz from './Appeals/Quiz';
import Result from './Appeals/Result';

const Account = () => {
    const navigate = useNavigate()

    const menus = JSON.parse(localStorage.getItem("loginData"))?.menus;

    const componentMap = {
        Info,
        Appeals,
        Statistics
    };

    return (
        <div className="account d-flex flex-wrap row-gap-4">
            <Menu steps={menus} />
            <div className="col-12 col-md-9 position-relative mb-5">
                <Routes>
                    {menus && menus.length > 0 && (
                        <>
                            <Route path="/" element={<Navigate to={`/account${menus[0].path}`} />} />

                            {menus.map((menu, index) => (
                                <Route
                                    key={menu.path}
                                    path={menu.path}
                                    element={
                                        React.createElement(componentMap[menu.component], {
                                            next: menus[index + 1]?.path
                                        })
                                    } />
                            ))}
                            
                            <Route path="/appeals/quiz" element={<Quiz />} />
                            <Route path="/appeals/result/:id" element={<Result />} />
                        </>
                    )}
                    <Route path="/404" replace />
                    <Route path="*" element={<Navigate to="/404" replace />} />
                </Routes>
            </div>
        </div>
    );
}

export default Account;
