import React, { useEffect, useState } from 'react';
import { Link, Navigate, Route, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import success from "../Home/img/success.svg";
import { Button } from '@mui/material';
import { useLocation, useHistory } from 'react-router-dom';
import logo from "../Home/img/appLogo.svg";


function ConfirmEmail() {
    const location = useLocation();
    const navigate = useNavigate()

    useEffect(() => {
        if (!location?.state?.navigate) {
            navigate("/")
        }
    }, [])

    return (
        <div className="d-flex align-items-center justify-content-center text-center login">
            <div className="row justify-content-center align-items-center col-10 col-md-7 col-lg-6 col-xl-4 gap-5 p-5 animate__animated animate__slideInUp">
                <div className="d-flex flex-column gap-2 align-items-center justify-content-center">
                    <img src={logo} alt="logo picture here" />
                    <h3 className='m-0'> Zəhmət olmasa elektron poçtunuza göndərilən təsdiq linkinə keçid edin</h3>
                </div>
            </div>
        </div >
    );


}



export default ConfirmEmail;
