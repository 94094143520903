import React, { useState, useEffect } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import "../../App.scss";
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { Button } from '@mui/material';
import InputMask from 'react-input-mask';
import logo from "../Home/img/appLogo.svg";
import Input from '@mui/joy/Input';
import { request } from '../../config';
import TextField from '@mui/material/TextField';
import OtpInput from 'react-otp-input';

function OTP() {
    const [showPassword, setShowPassword] = useState(false);
    const [showRepeatPassword, setShowRepeatPassword] = useState(false);
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [lastname, setLastname] = useState('');
    const [mobile, setMobile] = useState('');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');

    // Errors
    const [passwordError, setPasswordError] = useState('');
    const [repeatPasswordError, setRepeatPasswordError] = useState('');
    const [mobileError, setMobileError] = useState('');
    const [formError, setFormError] = useState('');
    const [otp, setOtp] = useState('');

    const [isTimerExpired, setIsTimerExpired] = useState(false);
    const [remainingTime, setRemainingTime] = useState(3 * 60);

    useEffect(() => {
        const timer = setInterval(() => {
            setRemainingTime((prevTime) => {
                if (prevTime <= 0) {
                    clearInterval(timer);
                    setIsTimerExpired(true);
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, []);

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    const navigate = useNavigate();

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowRepeatPassword = () => setShowRepeatPassword((show) => !show);

    const validateForm = () => {
        let isValid = true;

        // const passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[A-Z]).{8,}$/;

        if (mobile == '' || mobile.indexOf("_") !== -1) {
            setMobileError("Mobil nömrə düzgün daxil edilməmişdir")
            isValid = false;
        }
        else {
            setMobileError("")
            isValid = true;
        }

        // if (!passwordRegex.test(password)) {
        //     setPasswordError('Şifrə ən azı 8 simvol uzunluğunda, 1 simvol, 1 böyük hərf və 1 rəqəm içərməlidir');
        //     setRepeatPasswordError('');
        //     isValid = false;
        // }
        // else {
        if (password !== repeatPassword) {
            setRepeatPasswordError('Şifrələr uyğun gəlmir');
            isValid = false;
        }
        else {
            isValid = true
            setPasswordError('');
            setRepeatPasswordError('');
        }
        // }

        return isValid;
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            try {
                // const response = await fetch('https://mobile2.turanbank.az:5555/hr/register/registerUser', {
                //     method: 'POST',
                //     headers: {
                //         "Authorization": 'Basic YXBwbGljYW50OlR1ckBuQXBwbGljYW50',
                //         'lang': 'AZ',
                //         "Content-Type": "application/json",
                //     },
                //     body: JSON.stringify({
                //         email,
                //         name,
                //         lastname,
                //         mobile: getPhoneNumber(mobile),
                //         password,
                //         repeatPassword
                //     }),
                // });

                // const data = await response.json();
                const body = {
                    email,
                    name,
                    lastname,
                    // mobile: getPhoneNumber(mobile),
                    password,
                    repeatPassword
                }

                const data = await request("hr/register/registerUser", body, 'POST');

                if (data.code == 14) {
                    localStorage.clear();
                    navigate('/login');
                }

                if (data.code === 0) {
                    navigate('/confirmEmail', { state: { navigate: true } });
                }
                else {
                    setFormError(data.message || "Məlumatlar tam daxil edilməyib")
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    const handleInvalid = (e, text) => {
        e.target.setCustomValidity(text); // Set custom validity message
    };

    return (
        <div className="d-flex align-items-center justify-content-center text-center login">
            {/* <img src={vector} alt="" className='vector' /> */}
            <div className="row justify-content-center col-10 col-md-7 col-lg-6 col-xl-4 gap-5 p-5 animate__animated animate__slideInUp">
                {/* <div className="d-flex align-items-center justify-content-center animate__animated animate__slideInUp animate__slow"> */}
                {/* <img src={logo} alt="" /> */}
                <h2 className='m-0'>OTP kodu daxil edin</h2>
                {/* </div> */}
                <form onSubmit={handleSubmit} className='d-flex row-gap-3 flex-column otp_form text-start' autoComplete="off">
                    <OtpInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        shouldAutoFocus
                        className="justify-content-between"
                        renderSeparator={<span>-</span>}
                        renderInput={(props) => <input {...props} disabled={isTimerExpired} />}
                    />
                    {/* {passwordError && <p className="error-message">{passwordError}</p>}
                    {repeatPasswordError && <p className="error-message">{repeatPasswordError}</p>}
                    {mobileError && <p className="error-message">{mobileError}</p>}
                    {formError && <p className="error-message">{formError}</p>} */}
                    <p>{formatTime(remainingTime)}</p>
                    <Button type='submit' className='white p-3' disabled={isTimerExpired || otp.length != 6}>Qeydiyyat</Button>
                    {/* <p>Hesabınız var? <NavLink to="/login" className="nav-link">Daxil olun</NavLink></p> */}
                    {/* {formError && <p className="error-message">{formError}</p>} */}
                </form>
            </div>
        </div >
    );
}

export default OTP;