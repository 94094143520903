import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import "../../App.scss";
import logo from "../Home/img/appLogo.svg";
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { Button, TextField } from '@mui/material';
import { request } from "../../config"

export default function Register() {
    const [message, setMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });

    const navigate = useNavigate();

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            const response = await request('auth/v1/login', formData, 'POST');

            if (response.code == "SUCCESS") {
                localStorage.setItem("loginData", JSON.stringify(response.data.loginData))
                localStorage.setItem("token", JSON.stringify(response.data.token))
                navigate("/account/info")
            }
            else {
                setMessage(response?.message ?? '')
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleInvalid = (e, text) => {
        e.target.setCustomValidity(text); // Set custom validity message
    };

    const setValues = (e, message, minLength, maxLength, regex) => {
        const { name, value } = e.target;
        let isValid = true;
    
        if ((regex && !regex.test(value)) || (minLength && value.length < minLength) || (maxLength && value.length > maxLength)) {
            isValid = false;
        }
    
        e.target.setCustomValidity(isValid ? '' : message);
    
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <div className="d-flex align-items-center justify-content-center text-center login animate__animated animate__slideInUp">
            <div className="row justify-content-center col-10 col-lg-6 gap-5 p-5">
                <div className="d-flex align-items-center justify-content-center animate__animated animate__slideInUp animate__slow">
                    <img src={logo} alt="logo picture here" />
                    <h2 className='m-0'> Daxil ol</h2>
                </div>
                <form onSubmit={handleSubmit} className='d-flex row-gap-3 flex-column'>
                    <TextField required label="E-mail" inputProps={{ maxLength: 250, minLength: 8 }} variant="standard" type='email' name="email" value={formData.email}
                        onInvalid={(e) => handleInvalid(e, 'Düzgün email formatı daxil edin')}
                        onChange={(e) => setValues(e, 'Düzgün email formatı daxil edin', 8, 250, /^[^\s@]+@[^\s@]+\.[^\s@]+$/)} />

                    <TextField required name="password" inputProps={{ maxLength: 24 }} value={formData.password} InputProps={{ endAdornment: showPassword ? <VisibilityOutlined onClick={handleClickShowPassword} /> : <VisibilityOffOutlined onClick={handleClickShowPassword} /> }} label="Şifrə" variant="standard" type={showPassword ? "text" : "password"}
                        onInvalid={(e) => handleInvalid(e, 'Şifrəni daxil edin')}
                        onChange={(e) => setValues(e, 'Şifrəni daxil edin', null, 24,)} />

                    {message && <p className="error-message">{message}</p>}
                    
                    <NavLink to="/changePassword" className="nav-link">Şifrəni unutmuşam</NavLink>

                    <Button type='submit' disabled={isSubmitting} variant="contained" className='white p-3'>Daxil ol</Button>

                    <p>Hesabınız yoxdur? <NavLink to="/register" className="nav-link">Qeydiyyatdan keçin</NavLink></p>
                </form>
            </div>
        </div >
    );
}
