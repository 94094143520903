import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import "../../../App.scss";
import { Button, TextField, Autocomplete } from '@mui/material';
import { request } from '../../../config';
import { Modal, ModalClose, ModalDialog, DialogTitle } from '@mui/joy';
import Loading from '../../../comps/Loading';

export default function Register() {
    const [message, setMessage] = useState('');
    const [params, setParams] = useState();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [errorFields, setErrorFields] = useState();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        examLang: '',
        language: '',
        group: null,
        subGroups: [],
        score: '',
        talentScore: '',
        groupId: null,
        subGroupIds: []
    });

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const [paramsResponse, personalInfoResponse] = await Promise.all([
                request('user/v1/personal-info-params', null, 'POST'),
                request('user/v1/personal-info', null, 'POST')
            ]);

            if (paramsResponse.code === "SUCCESS") {
                setParams(paramsResponse.data);
            } else {
                setMessage(paramsResponse.message ?? '');
            }

            if (personalInfoResponse.code === "SUCCESS") {
                const { groupId, subGroupIds, examLang } = personalInfoResponse.data;
                const { groups, languages } = paramsResponse.data;

                const group = groups.find(group => group.id === groupId);
                const language = examLang ? languages.find(language => language.name === examLang) : null;
                const subGroups = (group && group.subGroups) ? group.subGroups.filter(subGroup => subGroupIds.includes(subGroup.id)) : null;

                setFormData({ ...personalInfoResponse.data, group, subGroups, language });
            } else {
                setMessage(personalInfoResponse.message ?? '');
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false)
        }
    };


    const handleInvalid = (e, text) => {
        e.target.setCustomValidity(text); // Set custom validity message
    };

    const setValues = (e, message, minLength, maxLength, regex) => {
        const { name, value } = e.target;
        let isValid = true;

        if ((regex && !regex.test(value)) || (minLength && value.length < minLength) || (maxLength && value.length > maxLength)) {
            isValid = false;
        }

        message && e.target.setCustomValidity(isValid ? '' : message);

        if (name == 'score') {
            regex.test(value) &&
                setFormData({
                    ...formData,
                    [name]: value,
                });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleSubmit = async (e) => {
        setLoading(true)
        try {
            const response = await request('user/v1/save-personal-info', formData, 'POST');

            if (response.code == "SUCCESS") {
                setOpen(true)
                setTimeout(() => {
                    setOpen(false);
                    setMessage('')
                    fetchData();
                }, 1000);
            }

            setMessage(response?.message ?? '')
            setErrorFields(response?.data ?? null)

        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false)
        }
    };

    return (
        <>
            {loading ? <Loading /> : formData && params &&
                <div className="col-10 col-lg-8 col-xl-6 mx-auto animate__animated animate__fadeIn">
                    <h5 className='mb-4'>Məlumatlar</h5>
                    <form className='d-flex row-gap-3 flex-column w-100' autoComplete="off" onSubmit={handleSubmit}>
                        <TextField InputProps={{ readOnly: true }} value={JSON.parse(localStorage.getItem('loginData'))?.personalInfo?.name} label="İstifadəçi adı" variant="standard" type='string' />

                        <TextField InputProps={{ readOnly: true }} value={JSON.parse(localStorage.getItem('loginData'))?.personalInfo?.email} label="E-mail" inputProps={{ maxLength: 250, minLength: 8 }} variant="standard" type='email' name="email" />

                        <Autocomplete
                            name='group'
                            options={params?.groups}
                            getOptionLabel={(option) => option.name ?? ''}
                            value={formData.group}
                            onChange={(event, newValue) => setFormData({
                                group: newValue,
                                groupId: newValue?.id,
                            })}
                            renderInput={(params) => (
                                <TextField {...params} error={errorFields?.groupId ? true : false} label="Qrup" variant="standard" />
                            )} />

                        {formData.group?.subGroups &&
                            <Autocomplete
                                name='subGroups'
                                multiple
                                disableCloseOnSelect
                                options={formData.group?.subGroups}
                                getOptionLabel={(option) => option.name ?? ''}
                                value={formData.subGroups || []}
                                onChange={(event, newValue) => setFormData({
                                    ...formData,
                                    subGroups: newValue,
                                    subGroupIds: newValue.map(item => item?.id),
                                })}
                                renderInput={(params) => (
                                    <TextField {...params} error={errorFields?.subGroupIds ? true : false} label="Alt qrup" variant="standard" />
                                )}
                            />}

                        <Autocomplete
                            name='examLang'
                            options={params?.languages}
                            getOptionLabel={(option) => option.name ?? ''}
                            value={formData.language}
                            onChange={(event, newValue) => setFormData({
                                ...formData,
                                language: newValue,
                                examLang: newValue?.name,
                            })}
                            renderInput={(params) => <TextField {...params} error={errorFields?.examLang ? true : false} label="İmtahan verdiyi dil" variant="standard" />} />

                        <TextField
                            name='score'
                            error={errorFields?.score ? true : false}
                            value={formData.score ?? ''}
                            inputProps={{ maxLength: 6 }}
                            onInvalid={(e) => handleInvalid(e, 'Balı daxil edin')}
                            onChange={e => setValues(e, 'Qəbul balını daxil edin', null, 700, /^(?:(?!0)\d{0,3}(?:\.\d{0,2})?|700(?:\.0{0,2})?)?$/)}
                            label="Qəbul balı" variant="standard" type='string' />

                        {formData.group?.talentScoreRequired ?
                            <TextField
                                error={errorFields?.talentScore ? true : false}
                                name='talentScore'
                                value={formData.talentScore ?? ''}
                                inputProps={{ maxLength: 10 }}
                                onChange={e => setValues(e, '', null, null, /^\d*\.{0,1}(\d{1,2})?$/)}
                                label="İstedad balı" variant="standard" type='string' /> : null}

                        {message && !open && <p className="error-message m-auto">{message}</p>}

                        <Button type='submit' variant="contained" className='white p-2'>Yadda saxla</Button>
                        {/* <Button type='button' className='gray p-2'>Ləğv et</Button> */}
                    </form>
                </div>}
            <Modal open={open}>
                <ModalDialog variant="plain" className="text-center p-5" size="lg">
                    <svg width="56" height="56" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" className='m-auto'>
                        <path d="M17.4 30L25.8 38.4L42.6 21.6M58 30C58 45.464 45.464 58 30 58C14.536 58 2 45.464 2 30C2 14.536 14.536 2 30 2C45.464 2 58 14.536 58 30Z" stroke="#06C7BB" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <DialogTitle className="text-center m-auto">{message}</DialogTitle>
                </ModalDialog>
            </Modal>
        </>
    );
}